import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles, Box, useMediaQuery, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { H3, Text } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import rightTriangle from '@images/blue_gradient_triangle_right.svg'
import mobileFrame from '@images/mobile-phone-frame.svg'
import bottomShadow from '@images/half-ellipse-shadow-bottom.svg'
import { monochrome, colorIsDark } from '@helpers/palette'

const TwoColHighlightsWithImage = (props) => {
  const {
    addShadowToImage,
    backgroundColor,
    highlightTitleColor,
    description,
    headline,
    highlights,
    image,
    imageOnRight,
    isMobileScreenshot,
    showImageOnMobile,
    variants,
    imagePaddingTop,
    imagePaddingOutside,
    imagePaddingBottom,
    imageAlign,
    mobileImageOnBottom,
  } = props.blok

  const { isConnected } = props // passed from ConnectedTwoColumnHighlights

  const isVariantInfrastructure = variants === 'industries-infrastructure'

  const imageStyles = {
    width: '100%',
    minWidth: '100%',
    objectFit: 'contain',
  }

  let imagePosition
  switch (imageAlign) {
    case 'flex-start':
      imagePosition = 'top'
      break
    case 'center':
      imagePosition = 'center'
      break
    case 'flex-end':
      imagePosition = 'bottom'
      break
    default:
      imagePosition = 'center'
  }

  const useStyles = makeStyles((theme) => ({
    background: {
      background:
        variants === 'homepage' &&
        `linear-gradient(90deg, ${
          imageOnRight
            ? 'rgba(255, 255, 255, 0.00)'
            : 'rgba(204, 204, 204, 0.15)'
        } 0%, ${
          imageOnRight
            ? 'rgba(204, 204, 204, 0.15)'
            : 'rgba(255, 255, 255, 0.00)'
        } 100%)`,
    },
    mainContainer: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      color: colorIsDark(backgroundColor?.color) && theme.palette.common.white,
      backgroundColor:
        (variants === 'homepage' && 'none') ||
        (backgroundColor?.color && monochrome(backgroundColor.color)) ||
        (variants === 'blue-background' && theme.palette.primary.main),
      [theme.breakpoints.up('sm')]: {
        margin: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: variants === 'homepage' && '30px 0',
      },
    },
    imageOnRight: {
      zIndex: '1',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: mobileImageOnBottom ? 'column' : 'column-reverse',
      },
    },
    imageOnLeft: {
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('sm')]: {
        flexDirection: mobileImageOnBottom ? 'column' : 'column-reverse',
      },
    },
    contentBox: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    mobileFrameLink: {
      '& a': {
        display: 'block',
        lineHeight: 1.6,
      },
    },
    imageContainer: {
      display: 'flex',
      alignItems: imageAlign || 'center',
      justifyContent: 'center',
      width: isMobileScreenshot ? '45%' : '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: showImageOnMobile ? 'inherit' : 'none',
        alignItems: 'center',
      },
    },
    imageBox: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      paddingTop:
        !isMobileScreenshot && !!imagePaddingTop && `${imagePaddingTop}%`,
      marginRight:
        !!imagePaddingOutside && imageOnRight && `${imagePaddingOutside}%`,
      marginLeft:
        !!imagePaddingOutside && !imageOnRight && `${imagePaddingOutside}%`,
      paddingBottom:
        !isMobileScreenshot && !!imagePaddingBottom && `${imagePaddingBottom}%`,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
        marginRight: 0,
        marginLeft: 0,
        paddingBottom: 0,
      },
      '& img': {
        padding: isMobileScreenshot ? '0 6.5% 5%' : 0,
        objectFit: 'contain',
        objectPosition: imagePosition,
      },
    },
    mobileFrame: {
      padding: '0 !important',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    bottomShadow: {
      padding: '0 !important',
      width: '110%',
      position: 'absolute',
      bottom: '6%',
      left: '-4.5%',
    },
    shadow: {
      '& .gatsby-image-wrapper': {
        boxShadow: imageOnRight
          ? '-10px 10px 15px rgba(0,0,0,0.07), -10px -10px 15px rgba(0,0,0,0.07)'
          : '10px 10px 15px rgba(0,0,0,0.07), 10px -10px 15px rgba(0,0,0,0.07)',
        [theme.breakpoints.down('sm')]: {
          boxShadow: 'none',
        },
      },
    },
    contentContainer: {
      padding: '0 1.5rem',
      [theme.breakpoints.up('md')]: {
        width: variants === 'products-overview' ? '50%' : '46%',
        minHeight: '500px',
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: variants === 'homepage' ? '425px' : '600px',
      },
    },
    mobileScreenshotContentWidth: {
      width: '35%',
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      minHeight: '30px',
      padding: '5px 12px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '& a': {
        color: theme.palette.text.white,
        border: '0px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: '0.711rem',
        '&:hover': {
          border: '0px',
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    highlightColumn: ({ isModalContent }) => ({
      padding: imageOnRight ? `0 2rem 0 1rem` : `0 1rem 0 2rem`,
      width: '65%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: isModalContent ? '1rem' : '0 48px 24px',
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        width: '48%',
        paddingBottom: '7%',
      },
      '& button': {
        width: '100%',
      },
    }),
    highlightColumnForm: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left !important',
      },
    },
    headlineDescContainer: ({ variants }) => ({
      display: variants === 'homepage' ? 'flex' : 'block',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    headline: ({ isModalContent }) => ({
      fontSize: '3.25rem',
      color: theme.palette.primary.main,
      display: 'block',
      textAlign: variants === 'homepage' ? 'center' : 'left',
      paddingTop: '45px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: isModalContent ? 0 : 'initial',
      },
    }),
    description: {
      color:
        (isConnected && !imageOnRight && theme.palette.text.white) ||
        theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        padding: '0px 10px 20px',
      },
      display: 'block',
      textAlign: variants === 'homepage' ? 'center' : 'left',
      margin: 'auto',
      fontSize: '1.5rem',
    },
    industryContainer: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    outerContainer: {
      backgroundColor: backgroundColor || theme.palette.background.slite,
    },
    triangle: {
      width: '100%',
      zIndex: '0',
      height: '140px',
      display: 'flex',
      justifyContent: imageOnRight ? 'flex-end' : 'flex-start',
      '& img': {
        transform: `scale(${imageOnRight ? '1' : '-1'}, 1)`,
        transformOrigin: 'center',
        position: 'relative',
        top: '-28rem',
        height: '35rem',
        [theme.breakpoints.down('sm')]: {
          top: '-10rem',
          height: '15rem',
        },
      },
    },
    imageFullWidth: {
      width: '100%',
    },
    outerContainerSpacing: {
      padding: '20px 0px',
    },
  }))

  const classes = useStyles({ isModalContent: props.isModalContent })
  const theme = useTheme()
  const isMQSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const bgColor = isConnected
    ? 'clear'
    : (backgroundColor &&
        backgroundColor.color &&
        monochrome(backgroundColor.color)) ||
      (variants === 'blue-background' && theme.palette.primary.main)

  return (
    <SbEditable content={props.blok}>
      <PageContainer backgroundColor={bgColor} className={classes.background}>
        <ContentContainer
          disableGutters
          noRight={variants !== 'homepage' && imageOnRight && !isMQSmall}
          noLeft={variants !== 'homepage' && !imageOnRight && !isMQSmall}
        >
          <Box
            className={classNames(classes.mainContainer, {
              [classes.outerContainer]: isVariantInfrastructure,
              [classes.outerContainerSpacing]: addShadowToImage,
            })}
          >
            {(headline || description) && (
              <ContentContainer>
                <Box className={classes.headlineDescContainer}>
                  {headline && <H3 className={classes.headline}>{headline}</H3>}
                  {description && (
                    <Text className={classes.description}>{description}</Text>
                  )}
                </Box>
              </ContentContainer>
            )}
            <Box
              className={classNames(classes.imageOnRight, {
                [classes.imageOnLeft]: !imageOnRight,
              })}
            >
              {isVariantInfrastructure ? (
                <Box
                  className={classNames(classes.highlightColumn, {
                    [classes.highlightColumnForm]: isVariantInfrastructure,
                  })}
                >
                  {renderBloks(highlights, {
                    variant: variants,
                    darkBg: colorIsDark(backgroundColor?.color),
                  })}
                </Box>
              ) : (
                <Box
                  className={classNames(classes.contentContainer, {
                    [classes.mobileScreenshotContentWidth]: isMobileScreenshot,
                  })}
                >
                  <Box className={classes.contentBox}>
                    {!!props.blok &&
                      !!highlights &&
                      renderBloks(highlights, {
                        isConnected: isConnected,
                        highlightTitleColor: highlightTitleColor,
                        imageOnRight: imageOnRight,
                        variant: variants,
                        darkBg: colorIsDark(backgroundColor?.color),
                      })}
                  </Box>
                </Box>
              )}
              <Box
                className={classNames(classes.imageContainer, {
                  [classes.industryContainer]: isVariantInfrastructure,
                })}
              >
                <Box
                  className={classNames(classes.imageBox, {
                    [classes.shadow]: addShadowToImage,
                  })}
                >
                  {renderBloks(image, { style: imageStyles })}
                  {!!isMobileScreenshot && (
                    <img
                      src={mobileFrame}
                      alt="mobileFrame"
                      className={classes.mobileFrame}
                    ></img>
                  )}
                  {!!isMobileScreenshot && (
                    <img
                      src={bottomShadow}
                      alt="shadow"
                      className={classes.bottomShadow}
                    ></img>
                  )}
                </Box>
              </Box>
            </Box>
            {variants === 'blue-triangle' && (
              <Box className={classes.triangle}>
                <img src={rightTriangle} alt="triangle"></img>
              </Box>
            )}
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}
export default TwoColHighlightsWithImage
